/* Container holding the image and the text */
.container {
    width: 48px;
    height: 58px;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    color: white;
}

.imgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

    
.chipImg {
    width: 45px;
    height: 45px;
    pointer-events: none;
}

/* Centered text */
.centered {
  font-size: 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

@media (max-width: 870px) {
    .centered {
        transform: rotate(-90deg);
    }
}
