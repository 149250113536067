
* {
  margin:0;
  padding:0;
  }

:root {
  --end-rotation: 1894.73deg;
}


.main-section {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.section-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: block;
  -webkit-filter: blur(2px) brightness(0.5);
	filter: blur(2px) brightness(0.5);
    -moz-filter: blur(2px) brightness(0.5);
    -o-filter: blur(2px) brightness(0.5);
    -ms-filter: blur(2px) brightness(0.5);
    top: 0;
}

.nav-section {
  width: 100%;
  position: relative;
  z-index: 1;
  padding-bottom: 35px;
}

.navbar {
  padding-left: 8%;
  font-family: 'Poppins', sans-serif;
  padding-top: 15px;
}

.nav-logo {
  width: 80px;
  height: auto;
  cursor: pointer;
}

.nav-links-section {
  position: relative;
  padding-right: 8%;
  cursor: default;
  float: right;
}

.nav-link {
  display: inline-block;
  margin: 20px;
  margin-top: 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  letter-spacing: 1px;
  text-decoration: none;
  padding-bottom: 6px;
}

.nav-link:hover {
  border-bottom: 3px solid #F02051;
}

.active {
  border-bottom: 3px solid #F02051;
}

.nav-social-links {
  display: inline-block;
  margin: 20px;
  margin-top: 0px;
  cursor: pointer;
}

.wallet {
  display: inline-block;
  margin: 20px;
  margin-top: 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  letter-spacing: 1px;
  text-decoration: none;

  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border: 2px solid white;
  border-radius: 8px;
  transition: all .3s;
}

.wallet:hover {
  background-color: #F02051;
  border: 2px solid #F02051;
}

.fab {
  color: white;
  font-size: 20px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  transition: all ease .3s;
  position: relative;
  top: 3px;
}

.fab:hover {
  color: #F02051;
}


.wallet-connected-wrapper {
  display: inline-block;
  margin: 20px;
  margin-top: 20px;
  padding: 7px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px;
  border: 2px solid white;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.fa-wallet {
  display: inline-block;
  margin-right: 10px;
  color: white;
  font-size: 18px;
}

.account {
  display: inline-block;
  color: white;
  font-size: 16px;
}



.hamburger {
	width: 40px;
	cursor: pointer;
  position: relative;
  top: 12px;
  margin-right: 20px;
  float: right;
  display: none;
}
	
.hamburger span {
	background-color: #fff;
	content: '';
	display: block;
	height: 5px;
	margin: 7px 0;
	transition: all .2s;
	-webkit-transition: all .2s;
	-moz-transition: all .2s;
	-o-transition: all .2s;
}

#dropdown {
	background-color: rgb(60, 10, 111);
	position: absolute;
	right: 0;
	width: 0;
	height: 100vh;
	z-index: 4;
	transition: width 0.3s ease;
  position: fixed;
}

#dropdown-content {
	margin-top: 5px;
}

#mobile-navLinks {
	position: relative;                  
	width: 100%;
	margin: 0 auto;
	display: none;
	animation-duration: .4s;
	animation-delay: 0.9s;
}

.mobile-nav-link {
	padding-top: 12px;
	padding-bottom: 12px;
	display: block;
	font-size: 18px;
	color: #fff !important;
	cursor: pointer;
	transition: all 0.3s ease;
	text-align: center;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  letter-spacing: 1.5px;
}

.mobile-nav-link:hover {
	color: #F02051 !important;
}

.mobile-wallet {
  display: block;
	padding: 15px;
  width: 200px;
  margin: 0 auto;
  font-size: 17px;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  letter-spacing: 1.3px;
  text-decoration: none;
  text-align: center;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
  margin-bottom: 30px;
}

.mobile-wallet-connected-wrapper {
  margin: 0 auto;
  display: block;
  width: 150px;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 10px;
  padding-left: 12px;
  border-radius: 8px;
  border: 2px solid white;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.mobile-fa-wallet {
  display: inline-block;
  margin-right: 10px;
  color: white;
  font-size: 24px !important;
}

.mobile-account {
  display: inline-block;
  color: white;
  font-size: 24px;
}

.mobile-nav-logo {
  width: 80px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}

.mobile-nav-social-links {
  width: 133px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.exit-section {
  width: 100%;
  position: relative;
  top: 10px;
  margin-bottom: 25px;
  text-align: right;
}

#dropdown-close {
  font-size: 35px;
	color: white;
	cursor: pointer;
  position: relative;
  z-index: inherit;
  padding: 20px;
  margin-bottom: 20px;
}



@media (max-width: 1200px) {

  .navbar {
    padding-left: 20px;
  }
  
  .nav-links-section {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .nav-section {
    padding-top: 12px;
  }
  
  .nav-logo {
    width: 70px;
  }
  
  .nav-link {
    display: inline-block;
    margin: 10px;
    margin-top: 12px;
    font-size: 14px;
  }
  
  .nav-link:hover {
    border-bottom: 2px solid #F02051;
  }
  
  .nav-social-links {
    margin: 10px;
  }
  
  .wallet {
    margin: 10px;
    margin-top: 22px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .wallet-connected-wrapper {
    display: inline-block;
    margin: 10px;
    margin-top: 22px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .fa-wallet {
    margin-right: 10px;
    font-size: 17px;
  }
  
  .account {
    font-size: 15px;
  }
  
}

@media (max-width: 768px) {
  .nav-links-section {
    display: none;
  }

  .hamburger {
    display: inline-block;
  }
}

#popup-wrapper {
  display: none;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.644); /* Black w/ opacity */
}

.popup {
  padding: 30px;
  visibility: hidden;
  width: auto;
  height: auto;
  position: absolute;
  background-color: rgba(60, 10, 111, 1);
  z-index: 10;
  left: 50%;                  
	top: 50%;
  transform: translate(-50%, -50%);
  animation-duration: .4s !important;
  border-radius: 10px;
  text-align: center;
}

#popup-howto {
  width: 100%;
  max-width: 700px;
}

.loading-text {
  text-align: center;
  font-size: 17px;
  color: white;
  letter-spacing: 1.2px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
}

.popup-text {
  text-align: center;
  font-size: 17px;
  color: white;
  margin-bottom: 30px;
  letter-spacing: 1.2px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
}

.popup-button {
  width: 50px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgb(136,0, 145);
  color: white;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 2px;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

#popup-text_info {
  text-align: center;
  font-size: 17px;
  color: white;
  margin-bottom: 30px;
  letter-spacing: 1.2px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.2;
}

.section-wrapper {
  background-color: rgba(111, 49, 173, 0.705);
  backdrop-filter: blur(3px);
  width: 1200px;
  height: auto;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  padding: 30px;
}

.howto-button {
  position: absolute;
  z-index: 2;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 6px;
  background-color: rgb(139, 11, 148);
  cursor: pointer;
  transition: all .3s;
}

.howto-button-text {
  font-size: 24px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.howto-button:hover {
  background-color: rgb(173, 69, 173);
}



.checkbox-wrap {
  position: absolute;
  z-index: 2;
  padding-top:2px;
  left: 150px;
}

input[type="checkbox"] {
  position: relative;
  width: 60px;
  height: 30px;
  -webkit-appearance: none;
  -webkit-appearance: none;
  background: rgb(136,0, 145);
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: background 300ms linear;
}

#ball-pivot {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  visibility: hidden;
  /* transform: translate(-50%, -50%); */
  /* animation: ballRotation 3s ease-out forwards; */
}
/* 
.wheel-pivot {
  animation: reverse-rotate 10s linear 0s infinite forwards;
} */

@keyframes reverse-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#ball { 
  width: 20px; 
  height: 20px;
  position: absolute;
  top: 22%;
  left: 52%;
  background-color: white; 
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

  
input[type="checkbox"]::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  top: 0px;
  left: 0px;
  border-radius: 20px;
  background-color: #fff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 300ms linear;
}  

input[type="checkbox"]:checked {
  background: rgb(250, 32, 251);
}
   
input[type="checkbox"]:checked::before {
  left: 35px;
  background-color: #fff;
}

.checkbox-text {
  font-size: 22px;
  font-weight: bold;
	color: #fff !important;
	text-align: center;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.2px;
  display: inline-block;
  position: relative;
  top: -7px;
}

#checkbox-text-1 {
  padding-right: 15px;
}

#checkbox-text-2 {
  padding-left: 15px;
}






.wheel-wrapper {
  width: 400px;
  height: auto;
  position: absolute;
  top: 100px;
  left: 60px;
}

.wheel-image {
  width: 400px;
  height: auto;
  position: relative;
  z-index: 1;
  display: block;
}

.green-result {
  background-color: rgb(62, 155, 107);
}

.red-result {
  background-color: rgb(198, 79, 89);
}

.black-result {
  background-color: rgb(11, 3, 63);
}


.green {
  color: rgb(62, 155, 107);
}

.red {
  color: rgb(198, 79, 89);
}

.black {
  color: rgb(11, 3, 63);
}

.result {
  /* background-color: rgb(136,0, 145); */
  border-radius: 50%;
  color: black;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.result-text {
  font-size: 55px;
  font-weight: bold;
  color: white;
  font-family: 'Poppins', sans-serif;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotate {
  animation: rotation 1s linear 0s infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ballRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(var(--end-rotation));
  }
}


.bet-wrapper {
  width: 100%;
  height: 90px;
  margin-bottom: 35px;
  position: relative;
}

.bet-info {
  margin-left: auto;
  float: right;
  display: flex;
  align-items: center;
}

.bet {
  padding: 10px;
  display: inline-block;
  background-color: rgb(111, 10, 119);
  border-radius: 15px;
  min-width: 100px;
}

.bet-left {
  margin-right: 25px;
}

.winning-numbers {
  display: flex;
  flex-direction: row;
  justify-content: center;
	font-size: 24px;
}


.winning-number {
  margin-left: 3px;
  margin-right: 3px;
}

.winning-section {
  padding: 10px;
  display: inline-block;
  background-color: #fff;
  border-radius: 15px;
  min-width: 100px;
  min-height: 50px;;
}

.winning-header {
	font-size: 14px;
	text-align: center;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.1px;
}

.bet-text {
	font-size: 14px;
	color: #fff !important;
	text-align: center;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.1px;
}

.bet-result {
	font-size: 24px;
  font-weight: bold;
	color: #fff !important;
	text-align: center;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.5px;
}









.controls-section {
  width: 720px;
  margin-left: auto;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1.4px;
  font-size: 15px;
  font-weight: 600;
  /* margin-bottom: 50px; */
}

.spin-button {
  width: 305px;
  display: inline-block;
}

.spin {
  width: 90px;
  height: 90px;
  background-color: rgb(72, 58, 99);
  position: relative;
  border: 10px solid rgba(147, 109, 216, 0.91);
  border-radius: 50%;
  display: inline-block;
  top: -30px;
  left: 17px;
  cursor: pointer;
}

.spin-image {
  width: 40px;
  height: 40px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.control-button {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  cursor: pointer;
  background:  linear-gradient(106.5deg, rgba(143, 103, 217, 0.91) 25%, rgba(254, 189, 255, 0.8));
  color: white;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  min-width: 175px;
  border-radius: 6px;
}

.disabled-control-button {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  cursor: pointer;
  color: white;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  min-width: 175px;
  border-radius: 6px;
  pointer-events: none;
  background-color: rgb(150, 150, 150);
}

.chips-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sm-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sm-margin {
  margin-left: 10px;
  margin-right: 10px
}

.chip-container {
  display: inline-block;
  color: #FFFFFF;
  text-align: center;
  position: relative;
}

.chip-margin {
  margin-left: 25px;
  margin-right: 25px;
}


.chip-centered {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.small-text {
  font-size: 14px;
}

.med-text {
  font-size: 18px;
}

.large-text {
  font-size: 20px;
}

#large-chip-image {
  width: 120px;
  cursor: pointer;
  filter: brightness(55%);
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.medium-chips {
  display: none;
  width: 350px;
  position: absolute;
  left: 75%;
  top: 30%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

#medium-chip-image {
  display: inline-block;
  width: 100px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  filter: brightness(55%);
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.small-chips {
  display: none;
  width: 350px;
  position: relative;
  margin: 0 auto;
}

#small-chip-image {
  width: 75px;
  height: auto;
  cursor: pointer;
  filter: brightness(55%);
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.left-chip {
  margin-left: 65px !important;
}

.activeChip {
  filter: brightness(100%) !important;
}



@media (max-width: 1350px) {
  .section-wrapper {
    width: 730px;
    border-radius: 20px;
    padding: 25px;
    padding-bottom: 0px;
  }

  .checkbox-wrap {
    left: 290px;
  }

  .checkbox-text {
    font-size: 18px;
  }

  #checkbox-text-1 {
    padding-right: 10px;
  }

  #checkbox-text-2 {
    padding-left: 10px;
  }
  
  .wheel-wrapper {
    width: 300px;
    position: absolute;
    top: 35px;
    left: 35px;
  }
  
  .wheel-image {
    width: 300px;
  }
  
  .result {
    width: 110px;
    height: 110px;
  }
  
  .result-text {
    font-size: 45px;
  }
  
  .bet-wrapper {
    height: 90px;
    margin-bottom: 250px;
  }
  
  .bet {
    padding: 8px;
    border-radius: 15px;
    min-width: 100px;
  }
  
  .bet-left {
    margin-right: 20px;
  }
  
  .bet-text {
    font-size: 13px;
    letter-spacing: 1px;
  }
  
  .bet-result {
    font-size: 20px;
    letter-spacing: 1.3px;
  }
  
  .medium-chips {
    display: block;
  }
  
  
  
  .controls-section {
    margin-bottom: 0px;
    margin: 0 auto;
  }
  
  
  .chips-section {
    display: none;
  }
}


@media (max-width: 870px) {
  #popup-howto {
    padding: 20px;
    max-width: none;
    width: 70%;
  }

  .section-wrapper {
    width: 450px;
    padding: 20px;
  }

  .checkbox-wrap {
    left: 20px;
    padding-top: 50px;
  }

  .bet-left {
    margin-right: 5px;
  }
  
  .wheel-wrapper {
    width: 300px;
    position: relative;
    left: 0;
    top: 115px;
    transform: none;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  
  .wheel-image {
    width: 300px;
  }
  
  .result {
    width: 110px;
    height: 110px;
  }
  
  .result-text {
    font-size: 45px;
  }
  
  .bet-wrapper {
    position: absolute;
    top: 20px;
  }
  
  .bet-info {
    margin-right: 40px;
  }
  
  .medium-chips {
    display: none;
  }

  .small-chips {
    display: block;
    margin-top: 170px;
    margin-bottom: 30px;
  }
  
  .table-section {
    position: relative;
    height: 718px;
    transform: rotate(90deg);
    left: -50%;
  }
  
  .controls-section {
    margin-bottom: 0px;
    margin: 0 auto;
  }
  
  
  .chips-section {
    display: none;
  }

  .spin-button {
    width: 305px;
    display: block;
    margin: 0 auto;
  }
  
  .spin {
    display: none;
  }

  .control-button {
    padding-top: 12px;
    padding-bottom: 12px;
    width: 260px;
    display: block;
    margin-right: 0px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .disabled-control-button {
    padding-top: 12px;
    padding-bottom: 12px;
    width: 260px;
    display: block;
    margin-right: 0px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .controls-section {
    width: auto;
    margin-bottom: 30px;
  }
}

@media (max-width: 550px) {

  #popup-info {
    width: 70%;
    padding: 20px;
  }

  .popup-loading {
    width: 70%;
    padding: 20px;
  }

  .popup-text {
    font-size: 15px;
    margin-bottom: 20px;
    letter-spacing: 1.0px;
    line-height: 1;
  }

  .section-wrapper {
    width: 280px;
    padding: 10px;
    border-radius: 10px;
    overflow-x: hidden;
  }

  .checkbox-wrap {
    left: 55px;
    top: 60px;
  }

  .table-section {
    left: -221px;
    top: -60px;
  }

  .bet-wrapper {
    width: 280px;
    top: 10px;
  }

  .bet-info {
    margin-right: 0px;
  }

  .bet-left {
    margin-right: 10px;
  }

  .bet {
    border-radius: 10px;
    min-width: 0px;
  }

  .bet-result {
    font-size: 17px;
    letter-spacing: 1.2px;
  }
  
  .wheel-wrapper {
    width: 250px;
    top: 145px;
    margin-bottom: 10px;
  }
  
  .wheel-image {
    width: 250px;
  }
  
  .result-text {
    font-size: 40px;
  }

  .small-chips {
    width: 280px;
    margin-top: 200px;
  }

  .small-chip-image {
    margin-left: 5px;
    margin-right: 5px;
    width: calc(33% - 10px);
  }

  .left-chip {
    margin-left: 50px;
  }

  .control-button {
    width: 80%;
  }

  .disabled-control-button {
    width: 80%;
  }

  .spin-button {
    width: 100%;
  }
}