* { 
  padding:0px; 
  margin:0px; 
  border: 0px;
  font-family: 'Poppins', sans-serif;
}

:root {
  --color-red: rgb(198, 79, 89);
  --color-green: rgb(62, 155, 107);
  --highlight: rgb(58, 158, 136);
}



.table-wrapper {
  position: relative;
  width: 718px;
  margin-left: auto;
  margin-bottom: 50px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.table {
  position: relative;
  z-index: 1;
}

.top-section {
}

.zero-split {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  position: relative;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.zero-split-column {
  height: 88px;
  width: 58px;
  border: 1px solid white;
  position: relative;
  color: white;
  background-color: var(--color-green);
  border-left: 2px solid white;
}

.numbers {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.numbers-row {
  display: block;
  height: 60px;
}

.numbers-row-top {
  border-top: 1px solid white;
}

.number {
  display: inline-block;
  width: 48px;
  height: 58px;
  border: 1px solid white;
  position: relative;
}

.number-red-color {
  background-color: var(--color-red);
  color: white;
}

.number-black-color {
  background-color: rgb(11, 3, 63);
  color: white;
}

.number-text {
  font-size: 30px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  pointer-events: none;
}

.dozen {
  position: relative;
  display: inline-block;
  height: 100%;
  vertical-align: top;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.dozen-column {
  width: 53px;
  height: 58px;
  border: 1px solid white;
  position: relative;
  border-right: 2px solid white;
}

.dozen-text {
  letter-spacing: 0px;
  word-spacing: 0px;
  white-space: nowrap;
  font-size: 19px;
  font-weight: 500;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  pointer-events: none;
}

.mid-section {
  position: relative;
  left: 60px;
  top: -1px;
}

.number-group {
  width: 198px;
  height: 45px;
  border: 1px solid white;
  display: inline-block;
  position: relative;
}

.number-group-text {
  font-size: 27px;
  font-weight: 500;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  pointer-events: none;
}

.bottom-section {
  position: relative;
  top: -8px;
  left: 60px;
}

.bottom-group {
  width: 98px;
  height: 45px;
  border: 1px solid white;
  display: inline-block;
  position: relative;
  border-bottom: 2px solid white;
}

.left {
  border-left: 2px solid white;
}

.right {
  border-right: 2px solid white;
}

.bottom-number-group-text {
  letter-spacing: 0px;
  word-spacing: 0px;
  white-space: nowrap;
  font-size: 21px;
  font-weight: 500;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.odd-even-text {
  font-size: 23px;
  font-weight: 500;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  pointer-events: none;
}


.diamond-red {
  width: 0px;
  height: 0px;
  border: 38px solid transparent;
  border-bottom:17px solid var(--color-red);
  position: relative;
  z-index: 2;
  top: -32px;
  left: 11px;
  pointer-events: none;
}

.diamond-red-2 {
  position: relative;
  z-index: 2;
  width: 0;
  height: 0;
  top: -32px;
  border: 38px solid transparent;
  border-top: 17px solid var(--color-red);
  left: 11px;
  pointer-events: none;
}

.diamond-black {
  width: 0px;
  height: 0px;
  border: 38px solid transparent;
  border-bottom:17px solid rgb(11, 3, 63);
  position: relative;
  z-index: 2;
  top: -32px;
  left: 11px;
  pointer-events: none;
}

.diamond-black-2 {
  position: relative;
  z-index: 2;
  width: 0;
  height: 0;
  top: -32px;
  border: 38px solid transparent;
  border-top: 17px solid rgb(11, 3, 63);
  left: 11px;
  pointer-events: none;
}

.collisions-wrapper {
  position: absolute;
  z-index: 2;
  top: -15px;
  left: 45px;
}

.collisions-top-section {

}

.collisions-numbers {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.collision-box-row {
  width: auto;
  height: 30px;
}

.collision-box {
  border: 1px solid rgba(0, 255, 0, 0);
  width: 28px;
  height: 28px;
  display: inline-block;
  position: relative;
}

#special-box-1 {
  height: 20px;
  vertical-align: top;
}

#special-box-2 {
  height: 20px;
}

#special-box-3 {
  height: 15px;
  position: relative;
  top: -6px;
}

#special-box-4 {
  height: 15px;
  position: absolute;
  display: block;
  z-index: 4;
  border: 1px solid rgba(0, 255, 0, 0);
  width: 28px;
  top: 80px;
  left: 0px;
}

#special-box-5 {
  height: 15px;
  position: absolute;
  display: block;
  z-index: 4;
  border: 1px solid rgba(0, 255, 0, 0);
  width: 28px;
  top: 112px;
  left: 0px;
}

#special-box-6 {
  height: 28px;
  position: absolute;
  display: block;
  z-index: 4;
  border: 1px solid rgba(0, 255, 0, 0);
  width: 43px;
  top: 90px;
  left: -45px;
}

#special-box-6 > * {
  left: 30px !important;
}

.highlight {
  background-color: var(--highlight);
}

.centre-box {
  width: 18px;
}

.end-box {
  width: 33px;
}




.chipImage {
  position: absolute;
  width: 45px;
  height: 45px;
  top: 0px;
  left: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  pointer-events: none;
}

.end-box > * {
  left: 10px !important;
}




@media (max-width: 870px) {
  .table-wrapper {
    margin-bottom: 0px;
  }

  .bottom-section {
    height: 45px;
  }

  .chipImage {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}

@media (max-width: 550px) {

}